<template>
  <section class="update-pass-section section">
    <div class="update-pass-container" v-if="isUrlValid && validView">
      <h1>Models 1A</h1>
      <h2 class="update-pass-title">Modificar contraseña</h2>
      <form class="update-pass-form">
        <img class="update-pass-img" src="/img/icons/apple-touch-icon-60x60.png" alt="icon" />
        <h3 class="update-pass-subtitle">{{ userName }}</h3>
        <h3 class="update-pass-subtitle email">{{ userEmail }}</h3>
        <div class="update-pass-block-input" ref="block">
          <label for="userPassword" class="update-pass-label" ref="label">{{ msg }}</label>
          <input class="update-pass-userPassword" type="password" name="userPassword" required v-model="userPassword" @keyup="validatePass" />
        </div>
        <div class="update-pass-block-input">
          <label for="passwordRepeat" class="update-pass-label">Repetir Contraseña</label>
          <input class="update-pass-userPassword" type="password" name="passwordRepeat" required v-model="passwordRepeat" @keyup="validatePass" />
        </div>
        <input class="update-pass-submit" type="button" value="Cambiar contraseña" disabled @click="onSubmit" ref="submit" />
      </form>
    </div>
    <div class="update-pass-container" v-else>
      <img class="update-pass-img" src="/img/icons/apple-touch-icon-60x60.png" alt="icon" />
      <h2 class="update-pass-title">Link no válido</h2>
    </div>
  </section>
</template>

<script>
import Swal from "sweetalert2";
import api from "@/api";

export default {
  name: "updatePass",
  data: function () {
    return {
      isUrlValid: false,
      userName: "pruebaNombre",
      userEmail: "prueba01@onegroup.com",
      msg: "Contraseña",
      userPassword: "",
      passwordRepeat: "",
      validView: false,
      code: "",
    };
  },
  methods: {
    validatePass: function () {
      /**Validación:
       * Contraseña iguales
       * Longitud mínimo de 4
       */
      this.$refs.label.classList.add("msg");
      this.$refs.submit.setAttribute("disabled", "");

      if (this.userPassword === this.passwordRepeat && this.userPassword.length >= 4) {
        this.msg = "Contraseña";
        this.$refs.label.classList.remove("msg");
        this.$refs.submit.removeAttribute("disabled");
      } else if (this.userPassword.length < 4) {
        this.msg = "Contraseña corta";
      } else if (this.userPassword !== this.passwordRepeat) {
        this.msg = "Contraseñas diferentes";
      }
    },
    onSubmit: async function () {
      this._dataSending();
      const _host = `${process.env.VUE_APP_API_HOST}user/update_pass`;
      const _data = { userEmail: this.userEmail, userPassword: this.userPassword, verifyCode: this.code };
      try {
        const response = await api.post(_host, _data);
        Swal.close();
        if (response.data.success) {
          Swal.fire({
            title: "Modificación correcta",
            text: "Contraseña modificada exitosamente.",
            icon: "success",
            confirmButtonText: "Ir a login",
          }).then(async (result) => await this.$router.replace("/login"));
        } else {
          this._dataError(response.data.message);
        }
      } catch (error) {
        console.log(error);
      }
    },
    _dataSending: function () {
      return Swal.fire({
        title: "Cambiando contraseña",
        text: "Sus datos están siendo validados",
        timerProgressBar: true,
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });
    },
    _dataError: function (msg) {
      Swal.fire({
        title: "Error",
        html: msg,
        icon: "error",
        confirmButtonText: "Ok",
        timerProgressBar: true,
        confirmButtonColor: "#bd0909",
      });
    },
  },
  async beforeMount() {
    this.code = localStorage.getItem("statepass");
    const fullPath = this.$route.fullPath;
    const _host = process.env.VUE_APP_API_HOST;
    const valid = _host.slice(-1) === "/" ? `user${fullPath}` : `/user${fullPath}`;
    const URL = `${process.env.VUE_APP_API_HOST}${valid}&code=${this.code}`;
    const data = await api.get(URL);
    this.validView = data.data.state;
  },
  beforeDestroy() {
    localStorage.removeItem("statepass");
  },
  created: function () {
    if (this.$route.query.email && this.$route.query.name) {
      this.userEmail = this.$route.query.email;
      this.userName = this.$route.query.name;
      this.isUrlValid = true;
    }
  },
};
</script>

<style lang="scss">
@import "@/assets/styles/vars.scss";
@mixin displayColumn($align: center, $justify: center) {
  display: flex;
  flex-direction: column;
  align-items: $align;
  justify-content: $justify;
}
.update-pass {
  &-section {
    height: 100vh;
    font-family: $primary_font;
    text-align: center;
    @include displayColumn();
  }
  &-container {
    padding: $mpadding * 2;
    border-radius: $mradius;
    background-color: $slider_bg;
    box-shadow: $dshadow;
  }
  &-title {
    color: $primary_color;
  }
  &-form {
    max-width: 320px;
    border-radius: $mradius;
    box-shadow: $dshadow;
    @include displayColumn();
  }
  &-img {
    margin: 30px 0;
  }
  &-subtitle {
    padding-bottom: 5px;
    &.email {
      margin-bottom: 10px;
    }
  }
  &-block-input {
    margin: 5px 0;
    width: 100%;
    position: relative;
    background-color: white;
    border-radius: 7px;
  }
  &-label {
    position: absolute;
    left: 7px;
    top: 0;
    font-size: 75%;
    &.msg {
      color: $primary_color;
    }
  }
  &-userPassword {
    height: 38px;
    width: 100%;
    padding-left: 7px;
    margin-top: 7px;
    font-size: 100%;
    background-color: transparent;
    border: none;
  }
  &-submit {
    margin-top: 5px;
    padding: 10px;
    width: 100%;
    height: 38px;
    font-size: 100%;
    color: white;
    background: $primary_color;
    border-radius: 7px;
    border: none;
    &:hover {
      background-color: $primary_color_hover;
      cursor: pointer;
    }
    &[disabled] {
      background-color: grey;
    }
  }
}
</style>
